import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const victoire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Victoire" />
    <h3 className='underline-title'>Victoire</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Il faut que je l'annonce à Bukavu sur place<br />
        Il faut que je porte l'heureuse nouvelle<br />
        Dans les rues crevassées de Kadutu-la-mère <br />
        Pour qu'elles soient joyeuses<br />
        ces filles Bashi<br />
      </p>
      <p>
        Pour qu'elles exaltent<br />
        Que leurs mères célèbrent mes exploits <br />
        Par des sarabandes des Mfumu réga (1)<br />
        Par des farandoles des Munyere shi (2)<br />
        Que le tambourin rituel résonne<br />
        Comme si un Mwami allait se sacrer<br />
      </p>
      <p>
        Retenez pour toujours:<br />
        Le fils du Soleil triompha des sorciers.<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">
      (1): Guérisseur de la tribu réga ou baréga du Kivu<br/>
      (2): Jeune fille de la tribu shi ou bashi du Kuvu
      </p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default victoire
